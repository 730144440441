import { BooleanStatus, Option } from 'types';

import { colors } from 'themes/foundations/colors';

import { IApplicant } from './applicant';
import { BadgesOption } from './badges';
import { IRequiredCertificate } from './certificate';
import { PaymentStatus } from './payment';
import { IReasonTemplate } from './reason';

export type IJobType = {
  id: number;
  title: string;
  number_available_position: number;
  number_available_company: number;
  status: BooleanStatus;
  description: string;
  wage_range?: string;
  logo_url?: string;
  hex_color?: string;
  updated_at?: string;
  created_at?: string;
};

export type IJobTemplate = {
  id: number;
  name: string;
  job_title: string;
  job_type_id: number;
  job_description: string;
  job_start_date: string;
  job_end_date: string;
  hourly_rate: number;
  special_instructions: string;
  food_hygiene_certificate_required: 1 | 0;
  status: BooleanStatus;
  isFromExistedJob?: boolean;
  candidate_instruction_filename?: FileList;
  url_candidate_instructions?: string;
  required_certificates: IRequiredCertificate[];
  job_type: IJobType;
};

export enum CreditLevel {
  Company = 0,
  Location = 1,
}

export enum JobStatus {
  Open = 1,
  Active = 2,
  Cancel = 4,
  Completed = 3,
  Pending = 5,
  // CompletedWoApplicant = 6,
  // CompletedWoManager = 7,
  ExpiredNoApplicants = 7,
  ExpiredNoSelection = 8,
  AllRejected = 10,
  AllCancelled = 11,
  ActiveWithRejected = 12,
  ActiveWithCancelled = 13,
  ClosedRejected = 14,
  ClosedCancelled = 15,
  PartiallyCompleted = 16,
  Incomplete = 17,
}

export enum JobStatusText {
  Open = 'jod_job_opening',
  Active = 'jod_job_active',
  Cancel = 'jod_job_canceled',
  Completed = 'jod_job_completed',
  Pending = 'jod_job_pending',
  ExpiredNoApplicants = 'jod_job_expired_no_applicants',
  ExpiredNoSelection = 'jod_job_expired_no_selection',
  AllRejected = 'jod_job_all_rejected_by_manager',
  ActiveWithRejected = 'jod_job_active_with_rejected',
  AllCancelled = 'jod_job_all_cancelled',
  ActiveWithCancelled = 'jod_job_active_with_cancelled',
  ClosedRejected = 'jod_job_closed_rejected',
  ClosedCancelled = 'jod_job_closed_cancelled',
  PartiallyCompleted = 'jod_job_partially_completed',
  Incomplete = 'jod_job_incomplete',
  Closed = 'jod_job_closed',
  Expired = 'jod_job_expired',
}

// export type JobStatusLabel = 'Open' | 'Active' | 'Cancelled' | 'Completed' | 'Pending';

// export const JOB_STATUS_LABEL: Record<JobStatus, JobStatusLabel> = {
//   [JobStatus.Pending]: 'Pending',
//   [JobStatus.Open]: 'Open',
//   [JobStatus.Active]: 'Active',
//   [JobStatus.Completed]: 'Completed',
//   [JobStatus.Cancel]: 'Cancelled',
// };
export const JOB_STATUS_COLOR: Record<JobStatus | JobStatusText, string> = {
  [JobStatus.Open]: colors.tango[300],
  [JobStatus.Active]: colors.success,
  [JobStatus.Cancel]: colors.danger,
  [JobStatus.Completed]: colors.secondary,
  [JobStatus.Pending]: colors.disable,
  // [JobStatus.CompletedWoApplicant]: colors.vulcan[300],
  // [JobStatus.CompletedWoManager]: colors.vulcan[300],
  [JobStatus.ExpiredNoApplicants]: colors.disable,
  [JobStatus.ExpiredNoSelection]: colors.disable,
  [JobStatus.AllRejected]: colors.danger,
  [JobStatus.ActiveWithRejected]: colors.success,
  [JobStatus.AllCancelled]: colors.danger,
  [JobStatus.ActiveWithCancelled]: colors.success,
  [JobStatus.ClosedRejected]: colors.danger,
  [JobStatus.ClosedCancelled]: colors.danger,
  [JobStatus.PartiallyCompleted]: colors.secondary,
  [JobStatus.Incomplete]: colors.danger,

  [JobStatusText.Open]: colors.tango[300],
  [JobStatusText.Active]: colors.success,
  [JobStatusText.Cancel]: colors.danger,
  [JobStatusText.Completed]: colors.secondary,
  [JobStatusText.Pending]: colors.disable,
  [JobStatusText.ExpiredNoApplicants]: colors.disable,
  [JobStatusText.ExpiredNoSelection]: colors.disable,

  [JobStatusText.AllRejected]: colors.danger,
  [JobStatusText.ActiveWithRejected]: colors.success,
  [JobStatusText.AllCancelled]: colors.danger,
  [JobStatusText.ActiveWithCancelled]: colors.success,
  [JobStatusText.ClosedRejected]: colors.danger,
  [JobStatusText.ClosedCancelled]: colors.danger,
  [JobStatusText.PartiallyCompleted]: colors.secondary,
  [JobStatusText.Incomplete]: colors.danger,
  [JobStatusText.Closed]: colors.danger,
  [JobStatusText.Expired]: colors.disable,
};

// export const JOB_STATUS_TEXT_LABEL: Record<JobStatusText, string> = {
//   [JobStatusText.Pending]: 'Pending',
//   [JobStatusText.Open]: 'Open',
//   [JobStatusText.Active]: 'Active',
//   [JobStatusText.Completed]: 'Completed',
//   [JobStatusText.Cancel]: 'Cancelled',
// };

// export const JOB_STATUS_LIST: Option<number>[] = Object.entries(JOB_STATUS_LABEL).map(
//   ([value, label]) => ({
//     value: +value,
//     label,
//   }),
// );

// export const JOB_STATUS_TEXT_LIST: Option[] = Object.entries(JOB_STATUS_TEXT_LABEL).map(
//   ([value, label]) => ({
//     value,
//     label,
//   }),
// );

export enum WorkerStatus {
  Approved = 1,
  ACK1 = 2,
  ACK2 = 3,
  ClockIn = 4,
  ClockOut = 5,
  Rejected = 6,
  SelfCanceled = 7,
  NoShow = 8,
  AdminDisable = 9,
  // NoHiring = 10,
  // NoApplicant = 11,
  PartiallyRejected = 12,
}

export enum WorkerStatusText {
  Approved = 'hired',
  ACK1 = 'ack1',
  ACK2 = 'ack2',
  ClockIn = 'clock_in',
  ClockOut = 'clock_out',
  Rejected = 'rejected_by_manager',
  RejectedPartially = 'partially_rejected_by_manager',
  SelfCanceled = 'self_canceled',
  NoShow = 'no_show',
  AdminDisable = 'admin_disabling_rejected',
  // NoHiring = 'no_hiring',
  // NoApplicant = 'no_applicant',
}

export type WorkerStatusLabel =
  | 'Rejected by Manager'
  | 'Partially Rejected by Manager'
  | 'Self-Cancelled'
  | 'Hired'
  | 'ACK1'
  | 'ACK2'
  | 'Clocked In'
  | 'Clocked Out'
  | 'No Show'
  | 'Admin-disabling Rejected';
// | 'No Hiring'
// | 'No Applicant';

// export const WORKER_STATUS_LABEL: Record<WorkerStatus, WorkerStatusLabel> = {
//   [WorkerStatus.Approved]: 'Hired',
//   [WorkerStatus.Rejected]: 'Rejected by Manager',
//   [WorkerStatus.SelfCanceled]: 'Self-Cancelled',
//   [WorkerStatus.ACK1]: 'ACK1',
//   [WorkerStatus.ACK2]: 'ACK2',
//   [WorkerStatus.ClockIn]: 'Clocked In',
//   [WorkerStatus.ClockOut]: 'Clocked Out',
//   [WorkerStatus.AdminDisable]: 'Admin-disabling Rejected',
//   [WorkerStatus.NoShow]: 'No Show',
// };

// export const WORKER_STATUS_LABEL_TEXT: Record<WorkerStatusText, WorkerStatusLabel> = {
//   [WorkerStatusText.Approved]: 'Hired',
//   [WorkerStatusText.Rejected]: 'Rejected by Manager',
//   [WorkerStatusText.SelfCanceled]: 'Self-Cancelled',
//   [WorkerStatusText.ACK1]: 'ACK1',
//   [WorkerStatusText.ACK2]: 'ACK2',
//   [WorkerStatusText.ClockIn]: 'Clocked In',
//   [WorkerStatusText.ClockOut]: 'Clocked Out',
//   [WorkerStatusText.AdminDisable]: 'Admin-disabling Rejected',
//   [WorkerStatusText.NoShow]: 'No Show',
// };

// export const WORKER_STATUS_LIST: Option<number>[] = Object.entries(WORKER_STATUS_LABEL).map(
//   ([value, label]) => ({
//     value: +value,
//     label,
//   }),
// );

// export const WORKER_STATUS_TEXT_LIST: Option<string>[] = Object.entries(
//   WORKER_STATUS_LABEL_TEXT,
// ).map(([value, label]) => ({
//   value,
//   label,
// }));

export interface IJobSlot {
  id: number;
  slot_start_date: string;
  slot_end_date: string;
  slot_users: Array<IActivatingWorker>;
}

export interface IJob {
  id: number;
  no_users_apply_job: number;
  no_users_selected: number;
  status: JobStatus;
  total_day: number;
  company_id: number;
  job_type_id: number;
  job_template_id: number;
  location_id: number;
  user_location_id: number;
  has_user_apply: 0 | 1;

  job_title: string;
  job_description: string;
  job_start_date: string;
  job_end_date: string;
  hourly_rate: number;
  bank_transfer: 0 | 1;
  food_hygiene_certificate_required: 0 | 1;
  date_of_week: string;
  job_template?: { id: number; name: string };
  job_type: {
    title: string;
    id: number;
    hex_color: string;
  };
  location: {
    id: number;
    name: string;
    url_logo?: string;
  };
  user_location?: {
    id: number;
    full_name: string;
  };
  special_instructions: string;
  slots?: IJobSlot[];
  cancellation_reason: IReasonTemplate;
  applicants: Array<
    Pick<IApplicant, 'full_name' | 'country_code' | 'contact_number' | 'is_deleted'>
  > | null;
  candidate_instruction_filename?: FileList;
  url_candidate_instructions?: string;
  required_certificates: IRequiredCertificate[];
}

export interface IJobRating {
  manager_feedback_job: string | null;
  manager_rating_job: number | null;
  cancellation_reason_id: number | null;
  cancellation_reason: string | null;
}

export interface IPaymentAdjustment {
  id: number;
  payment_id: number;
  break_time: number;
  clock_in: string;
  clock_out: string;
  net_pay: number;
  jod_credit: number;
  updated_at: string;
  status: number;
}

export interface IPaymentAdjustment {
  id: number;
  payment_id: number;
  break_time: number;
  clock_in: string;
  clock_out: string;
  net_pay: number;
  jod_credit: number;
  updated_at: string;
  status: number;
  cancellation_reason_id: number | null;
  cancellation_reason: string | null;
}

export interface IActivatingWorker {
  slot_id: number;
  app_user_id: number;
  status: WorkerStatus;
  ack2: 0 | 1;
  ack1: 0 | 1;
  clock_in_status: 0 | 1;
  clock_out_status: 0 | 1;
  break_time: null | number;

  ack2_datetime: null | string;

  clock_in: string | null;
  clock_out: string | null;

  actual_clock_in: null | string;
  actual_clock_out: null | string;

  admin_break_time: null | number;
  admin_clock_in: null | string;
  admin_clock_out: null | string;
  admin_hourly_rate: null | string;

  manager_feedback_slot: null | string;
  manager_rating_slot: null | number;

  created_at: string;
  updated_at: string;
  uuid: string;
  payment: null | {
    jod_credit: number;
    net_pay: number;
    payment_approval?: [IPaymentAdjustment | undefined];
  };
  payment_id: number;

  company?: {
    url_logo?: string;
    name: string;
  };

  user: {
    id: number;
    full_name: string;
    age: number;
    first_name: string;
    last_name: string;
    avatar_url?: string;
    total_working_hours?: number;
    total_completed_jobs?: number;
    rate: number;
    is_deleted?: 0 | 1;
    company?: {
      id: number;
      name: string;
      url_company_logo: string;
    };
    jobs?: [IJobRating | undefined];
    jod_badges?: Array<{
      id: number;
      icon: string;
      name: string;
      label: string;
      tooltip: string;
      status: 0 | 1;
    }>;
  };

  slot_user_transaction_logs: Array<IJobSlotUserTransactionLogs>;
}

export type JobTemplateOption = Option & IJobTemplate;

export type JobTypeOption = Option & IJobType;

export interface IJobSlotUserHistory {
  job_title: string;
  applicant_id: number;
  applicant_name: string;
  original_clock_in: string;
  original_clock_out: string;
  break_time: number;
  nric_fin: string;
  wage: number;
  total_hours: number;
  total_credits: number;
  manager_feedback: {
    rate: number;
    feedback: string;
  };
  applicant_feedback: {
    rate: number;
    feedback: string;
  };

  admin_clock_in?: string;
  admin_clock_out?: string;
  admin_break_time?: number;
  admin_total_credits: number;
  admin_wage: number;
  admin_total_hour: number;
  admin_jod_allowance: number;
  admin_jod_allowance_deduction: number;
  admin_comment?: string;
  adjusted_on?: string;
  has_adjustment: 0 | 1;
  credit_level: CreditLevel;
  payment_status: PaymentStatus;
  credit_before_transaction: number;
  credit_after_transaction: number;
  adjusted_jod_credit: number;
  location_title?: string;
  location_id?: string | number;
  job_id: number;
  slot_id: number;
  is_deleted?: BooleanStatus;
  adjustment_id: number;
  adjusted_clock_in: string;
  adjusted_clock_out: string;
  adjusted_total_hour: number;
  adjusted_break_time: number;
  adjusted_sponsored_break_time: number | null;
  adjusted_hourly_rate: number;
  adjusted_net_pay: number;
  readjusted_jod_credit: number;
  adjusted_insurance_amount: number;
  adjusted_sponsored_insurance_amount: number | null;
  adjusted_comment: string;
  adjusted_jod_allowance: number;
  adjusted_jod_allowance_deduction: 0 | 1;
  adjusted_date: string;
  adjusted_bank_code: string;
  adjusted_account_number: string;
  adjusted_account_holder_name: string;
  adjusted_credit_before_transaction: number;
  adjusted_credit_after_transaction: number;
  jod_badges?: Array<{
    id: number;
    icon: string;
    name: string;
    label: string;
    tooltip: string;
    status: 0 | 1;
  }>;
  company_badge_assignments?: Array<{
    badge_id: number;
    badge: BadgesOption;
  }>;
}

export interface IJobSlotUserFeedback {
  id: number;
  applicant_feedback: string;
  applicant_rating: number;
  manager_feedback: string;
  manager_rating: number;
  jod_badges?: Array<{
    id: number;
    icon: string;
    name: string;
    label: string;
    tooltip: string;
    status: 0 | 1;
  }>;
  company_badge_assignments?: Array<{
    badge_id: number;
    badge: BadgesOption;
  }>;
}

export interface IApplicantJobHistory {
  job_id: number;
  job_start_date: string;
  job_end_date: string;
  job_title: string;
  job_status: JobStatus;
  hourly_rate: number;
  location: { id: number; name: string };
  manager_rating: number;
  manager_feedback: string;
  company?: { id: number; name: string };
}

export interface IJobManager {
  job_id: number;
  job_title: string;
  location_name: string;
  job_start_date: string;
  job_end_date: string;
  hourly_rate: number;
  status: JobStatus;
  total_applied: number;
  total_hired: number;
}

export interface IJobSlotUserTransactionLogs {
  id: number;
  app_user_id: number;
  slot_id: number;
  job_id: number;
  payment_id: number;
  current_status: number;
  prev_status: number;
  file_url: string;
  update_reason: string;
  created_by: { id: number; first_name: string; last_name: string };
  created_at: Date;
  temporary_file_url: string;
}

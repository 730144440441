import { EnglishTranslation } from './en';

export const cn: EnglishTranslation = {
  'value.suspendedInvalid': 'Suspended & Invalid ID',
  'label.candidateInstructions': 'Upload Candidate Instructions (optional)',
  'label.candidateInstruction': 'Candidate Instructions',
  'label.employeeBadges': 'Employee Badges',
  'label.employerBadges': 'Employer Badges',
  'placeholder.uploadCandidateInstructions': 'Please upload candidate instructions.',
  'placeholder.uploadedCandidateInstructions': 'Candidate instructions uploaded.',
  'button.allCandidates': '所有候选人',
  'button.assignCredit': '分配信用',
  'button.rating': '评分',
  'button.download': '下载',
  'button.filter': '筛选',
  'button.sign_in': '登入',
  'button.submit': '提交',
  'button.save': '节省',
  'button.cancel': '取消',
  'button.close': '关',
  'button.newLocation': '新位置',
  'button.reset': '重置',
  'button.resetFilters': '重置过滤器',
  'button.confirm': '确认',
  'button.yesIDo': '是的，我愿意',
  'button.noIDoNot': '不，我不',
  'button.addCompany': '添加新公司',
  'button.addJobType': '添加新的工作类型',
  'button.addManager': '添加新经理',
  'button.addApplicant': '添加新申请人',
  'button.addNewSchool': '添加新学校',
  'button.addNewRole': '添加新角色',
  'button.addNewUser': '添加新用户',
  'button.addNewInternalRole': '添加新的内部角色',
  'button.addNewInternalUser': '添加新的内部用户',
  'button.addNewSuperHqUser': '添加新的超级总部用户',
  'button.addNewHQManager': '添加新的HQ Manager',
  'button.credit': '信用',
  'button.view': '看法',
  'button.edit': '编辑',
  'button.yes': '是的，我愿意',
  'button.newManager': '新经理',
  'button.manager': '经理',
  'button.enable': '使能够',
  'button.disable': '禁用',
  'button.newJobTemplate': '新的工作模板',
  'button.bulkFastExport': '批量快速出口',
  'button.downloadPayment': '下载付款',
  'button.downloadApplicantJobSummary': '申请人的工作摘要',
  'button.copy': '复制',
  'button.delete': '删除',
  'button.approveJob': '批准工作',
  'button.rejectJob': '拒绝工作',
  'button.copyJob': '复制工作',
  'button.copyURL': '复制深链接',
  'button.deleteJob': '删除工作',
  'button.editJob': '编辑工作',
  'button.done': '完毕',
  'button.back': '后退',
  'button.profile': '轮廓',
  'button.continue': '继续',
  'button.updateHourlyRate': '更新小时费率',
  'button.changePassword': '更改密码',
  'button.assign': '分配',
  'button.logOut': '登出',
  'button.ok': '好的',
  'button.detail': '细节',
  'button.companyHistory': '公司历史',
  'button.locationHistory': '位置记录',
  'button.areaHistory': '地区历史',
  'button.payslipRequest': '工资单请求',
  'button.approve': 'Approve',
  'button.disapprove': 'Disapprove',
  'button.slotUserStatusEdit': '编辑工作状态',
  'button.slotUserViewHistory': '查看历史',
  'button.addNewCertificate': '添加新证书',

  'label.superAdmin': '超级管理员',
  'label.hqUser': '总部用户',
  'label.areaUser': '区域用户',
  'label.areaApprovalManager': '区域审批经理',
  'label.locationUser': '位置用户',
  'label.managerDetail': '经理细节',
  'label.rememberMe': '记得我',
  'label.forgotPassword': '忘记密码了吗？',
  'label.phoneNumber': '电话号码',
  'label.contactNumber': '联系电话',
  'label.firstName': '名',
  'label.lastName': '姓',
  'label.name': '姓名',
  'label.displayName': '显示名称',
  'label.email': '电子邮件',
  'label.workEmail': '工作电子邮件',
  'label.notification': '通知',
  'label.address': '地址',
  'label.gender': '性别',
  'label.dateOfBirth': '出生日期',
  'label.referralCode': '推荐代码',
  'label.foodHygiene': '食物卫生',
  'label.foodHygieneCert': '食品卫生证书',
  'label.sunOfJODBadge': 'jod <0 />徽章的太阳',
  'label.newJobNotifications': '新工作<0 />通知',
  'label.unsubscribeNews': '退订<0 />新闻',
  'label.hqCompany': '总部公司',
  'label.deletedAccount': '删除帐户',
  'label.language': '语',
  'label.download': '下载',
  'label.workHistory': '工作经历',
  'label.currentOccupation': '现在的职业',
  'label.job': '工作',
  'label.jobs': '工作',
  'label.activeJobs': '活跃的工作',
  'label.openJobs': '开放工作',
  'label.completedJobs': '完成的工作',
  'label.currentMonth': '（这个月）',
  'label.todayHired': '今天雇用',
  'label.totalHired': '总雇用',
  'label.totalCredits': '总学分',
  'label.numberOfHiredStaff': '雇用员工人数',
  'label.hqAvailableJodCredits': '总部可用<0/> JOD积分',
  'label.locationJodCredits': '位置<0/> JOD积分',
  'label.consumedCredits': '消耗学分',
  'label.generalRating': '一般评级',
  'label.serviceContract': '服务合同',
  'label.locationName': '地点名称',
  'label.availableCredits': '可用学分',
  'label.areaManager': '区域经理',
  'label.locationManagers': '位置经理',
  'label.areaManagers': '区域经理',
  'label.function': '功能',
  'label.min': '最小',
  'label.max': '最大限度',
  'label.recent': '最近添加的',
  'label.recentAddCompanies': '最近添加了公司',
  'label.enableCompanies': '启用公司',
  'label.disableCompanies': '禁用公司',
  'label.partnerCompanies': '合作伙伴公司',
  'label.activeCompanies': '活跃公司',
  'label.companyName': '公司名称',
  'label.companyAddress': '公司地址',
  'label.companyStatus': '公司的身份',
  'label.businessRegistration': '业务注册',
  'label.minJODCreditLimit': '最低JOD信用额度',
  'label.jobTypeStatus': '工作类型的状态',
  'label.numberOfAvailablePositions': '可用职位的数量',
  'label.numberOfAvailableCompanies': '可用公司的数量',
  'label.minimumAvailablePositions': '最低可用位置',
  'label.maximumAvailablePositions': '最大可用位置',
  'label.minimumAvailableCompanies': '最低可用的公司',
  'label.maximumAvailableCompanies': '最大可用的公司',
  'label.minAvailablePositions': '最小可用位置',
  'label.maxAvailablePositions': '最大可用位置',
  'label.minAvailableCompanies': '最小可用的公司',
  'label.maxAvailableCompanies': '最大可用公司',
  'label.generalDescription': '一般描述',
  'label.jobDescription': '职位描述',
  'label.comment': '评论',
  'label.jobTitle': '职称',
  'label.hqManager': 'HQ Manager',
  'label.hqMangerName': 'HQ Manager名称',
  'label.contactInformation': '联系信息',
  'label.company': '公司',
  'label.recentAddHQUsers': '最近添加了总部用户',
  'label.companyHQMainContactUserDetails': '公司总部主要联系用户详细信息',
  'label.disableHQUsers': '禁用总部用户',
  'label.enableHQUsers': '启用总部用户',
  'label.hqUserStatus': '总部用户的状态',
  'label.totalNumberOfHQUsers': '<0 /> HQ用户的总数',
  'label.numberOfActiveHQUsers': '<0 />活动总部用户的数量',
  'label.numberOfDisableHQUsers': '<0 />禁用总部用户的数量',
  'label.recentAddJobTypes': '最近添加了工作类型',
  'label.disableJobTypes': '禁用工作类型',
  'label.enableJobTypes': '启用工作类型',
  'label.enabledCompanies': '启用了公司',
  'label.disabledCompanies': '残疾公司',
  'label.recentAdded': '最近添加了',
  'label.totalNumberOfLocations': '<0/>位置的总数',
  'label.locationStatus': '位置的状态',
  'label.minimumAvailableCredits': '最低可用积分',
  'label.maximumAvailableCredits': '最大可用积分',
  'label.minimumConsumedCredits': '最低消费信用',
  'label.maximumConsumedCredits': '最大消费的积分',
  'label.minAvailableCredits': '最小可用的积分',
  'label.maxAvailableCredits': '最大可用积分',
  'label.minConsumedCredits': '最小的学分',
  'label.maxConsumedCredits': '最大的学分',
  'label.minAssignedCredits': '最小分配学分',
  'label.maxAssignedCredits': '最大分配学分',
  'label.minAvailableConsumed': '最小可消耗',
  'label.maxAvailableConsumed': '最大可消耗',
  'label.minimumAvailableConsumed': '最低可用的消耗',
  'label.maximumAvailableConsumed': '最大可用的消耗',
  'label.locationAddress': '地址',
  'label.jobApprovalRequired': '工作批准<0/>需要',
  'label.locationManagerCanCreateJob': '位置经理<0/>也可以创建作业',
  'label.createFromTemplate': '仅从<0/>模板创建',
  'label.creditDeductionAtLocationLevel': 'JOD信用扣除<0/>位置级别',
  'label.autoJobPosting': '自动作业<0/>发布',
  'label.date': '日期',
  'label.inProgress': '进行中',
  'label.assignedCredits': '分配的积分',
  'label.applicantName': '申请人姓名',
  'label.applicantStatus': '申请人的身份',
  'label.applicantIsFeedback': '申请人的反馈',
  'label.applicantIsRating': '申请人的评级',
  'label.identityStatus': '身份状态',
  'label.managerFeedback': '经理的反馈',
  'label.managerRating': '经理的评分',
  'label.minimumRating': '最低评分',
  'label.maximumRating': '最大评分',
  'label.applicantsWithAmountJobs': '有工作量的申请人',
  'label.availableBreakLineCredits': '可用<0/>积分',
  'label.consumedBreakLineCredits': '消费<0/>信用',
  'label.disableLocations': '禁用位置',
  'label.enableLocations': '启用位置',
  'label.maskAsValid': '标记为有效',
  'label.maskAsInvalid': '标记为无效',
  'label.suspend': '暂停',
  'label.unsuspend': '取消暂停',
  'label.userAccountID': '用户帐户ID',
  'label.verifyCode': '验证码',
  'label.school': '学校',
  'label.averageRating': '平均评分',
  'label.totalWorkingHours_breakLine': '总工作<0 />小时',
  'label.totalCompletedJobs_breakLine': '总计完成<0 />作业',
  'label.totalCompletedJobs': '总计工作',
  'label.totalNumberOfApplicants': '<0 />申请人的总数',
  'label.numberOfActiveApplicants': '<0 />活动申请人的数量',
  'label.numberOfDisableApplicants': '<0 />禁用申请人的数量',
  'label.recentAddedApplicants': '最近添加了申请人',
  'label.disableApplicants': '禁用申请人',
  'label.enableApplicants': '启用申请人',
  'label.minRating': '最小评级',
  'label.maxRating': '最大评分',
  'label.applicantWithNumberOfJobs': '有工作数量的申请人',
  'label.manager': '经理',
  'label.managerType': '管理器类型',

  'placeholder.areaApprovalManager': '允许批准职位发布。',

  'label.approvalManagerQuestion': '这个经理能批准工作吗',

  'label.locations': '位置',
  'label.totalNoAreaManagers': '<0/>区域经理的总数',
  'label.totalNoLocationManagers': '<0/>位置经理的总数',
  'label.managerStatus': '经理的状态',
  'label.location': '地点',
  'label.institutionName': '机构名称',
  'label.instituteName': '研究所的名称',
  'label.description': '描述',
  'label.educationInstituteStatus': '教育学院的地位',
  'label.totalNumberOfEducationalInstitutes': '<0 />教育机构的总数',
  'label.recentAddedEducationInstitutes': '最近增加了教育机构',
  'label.disableEducationalInstitutes': '禁用教育机构',
  'label.enableEducationalInstitutes': '启用教育机构',
  'label.templateName': '模板名称',
  'label.jobStart': '工作开始',
  'label.jobEnd': '工作结束',
  'label.slotStart': '插槽开始',
  'label.slotEnd': '插槽结束',
  'label.wage': '工资',
  'label.applicationNo': '申请号',
  'label.hiredNo': '雇用号',
  'label.totalNumberOfJobs': '<0/>作业的总数',
  'label.jobWithApplicants': '与申请人一起工作',
  'label.jobStart&JobEnd': '工作开始和工作结束',
  'label.minimumWage': '最低工资',
  'label.maximumWage': '最高工资',
  'label.from': '从',
  'label.to': '至',
  'label.include': '包括',
  'label.exclude': '排除',
  'label.totalNumberOfJobTemplates': '<0/>工作模板的总数',
  'label.jobTemplateStatus': '作业模板的状态',
  'label.jobType': '工作类型',
  'label.hourlyRate': '每小时收费',
  'label.specialInstructions': '特别说明',
  'label.specialInstructions_breakLine': '特殊<0/>说明',
  'label.createdDate': '创建日期',
  'label.timeIn': '时间',
  'label.timeOut': '暂停',
  'label.totalHours': '全部小时数',
  'label.totalWages': '总工资',
  'label.status': '地位',
  'label.jobStatus': '工作现状',
  'label.jobIsStatus': '工作的状态',
  'label.createMonth': '创建一个月',
  'label.assigningAmount': '分配金额',
  'label.amount': '数量',
  'label.feedback': '反馈',
  'label.assigningDate': '分配日期',
  'label.startDate': '开始日期',
  'label.endDate': '结束日期',
  'label.minimumAssignAmount': '最低分配金额',
  'label.maximumAssignAmount': '最大分配金额',
  'label.minimumAssignedAmount': '最低分配量',
  'label.maximumAssignedAmount': '最大分配量',
  'label.selectAll': '全选',
  'label.jodID': '工作ID',
  'label.slotID': '老虎机ID',
  'label.clockIn': '时钟入',
  'label.clockOut': '时钟',
  'label.netPay': '净付款',
  'label.detail': '细节',
  'label.rating': '评分',
  'label.breakTime': '休息时间',
  'label.deductedCredits': '扣除的学分',
  'label.jodCredit': 'JOD信用',
  'label.jobHistory': '工作历史',
  'label.locationCredit': '位置信用',
  'label.processedDate': '处理日期',
  'label.jodAllowance': '津贴',
  'label.jodAllowanceDeduction': '津贴扣除',
  'label.jodAllowanceDeductionLabel': 'JOD津贴',
  'label.companyAllowanceDeductionLabel': '公司津贴',
  'label.bankPaid': '银行付款',
  'label.bankName': '银行名称',
  'label.bankHolderName': '银行持有人名称',
  'label.bankAccountNumber': '银行帐号',
  'label.paymentStatus': '付款的状态',
  'label.paymentAdjustmentStatus': 'Payment Adjustment Status',
  'label.recentAddedPayments': '最近增加了付款',
  'label.jobDetail': '工作细节',
  'label.jobDetails': '工作细节',
  'label.wagePerHour': '每小时工资',
  'label.jodAmendedDetails': 'JOD修改了细节',
  'label.noAdjustmentMade': '没有调整',
  'label.completedByAdmin': '由管理员完成',
  'label.unavailable': '不可用',
  'label.fullName': '全名',
  'label.totalJodCredits': 'JOD总数',
  'label.insuranceAmount': '保险金额',
  'label.netPayableAmount': '净应付金额',
  'label.accountHolder': '帐户持有人',
  'label.adjustedJODCredit': '调整后的JOD信用',
  'label.adjustedJodCredits': '调整后的JOD积分',
  'label.adjustedAt': '调整了',
  'label.totalNumberOfPayments': '<0 />付款的总数',
  'label.totalNumberOfProcessedPayments': '<0 />已处理的付款总数',
  'label.totalNumberOfUnpaidPayments': '<0 />未付付款的总数',
  'label.applicantID': '申请人ID',
  'label.applicantFirstName': '申请人的名字',
  'label.applicantLastName': '申请人姓',
  'label.applicantNRIC/FICNumber': '申请人NRIC/FIN编号',
  'label.applicantEmail': '申请人电子邮件',
  'label.applicantContactNumber': '申请人联系电话',
  'label.applicantGender': '申请人性别',
  'label.applicantDoB': '申请人DOB',
  'label.yearOld': '{{age}}年龄',
  'label.applicantAge': '申请人年龄',
  'label.applicantAddress': '申请人地址',
  'label.applicantRating': '申请人评级',
  'label.applicantFeedback': '申请人反馈',
  'label.applicantEducationalInstitution': '申请人教育机构',
  'label.applicantBankName': '申请人银行名称',
  'label.applicantV1BankName': '申请人 V1 银行名称',
  'label.applicantBankAccountName': '申请人银行帐户名称',
  'label.applicantBankAccountNumber': '申请人银行帐号',
  'label.jobStartDateTime': '工作开始日期',
  'label.jobEndDateTime': '工作结束日期',
  'label.totalExpectedHours': '总预期小时',
  'label.totalExpectedWages': '总预期工资',
  'label.jobCreatedDateTime': '作业创建了DateTime',
  'label.daysOfJobCreatedAndJobStart': '工作创造了工作开始（天）',
  'label.hoursOfJobCreatedAndJobStart': '工作创造了工作开始（小时）',
  'label.applicantAppliedDateTime': '申请人应用日期',
  'label.daysOfJobCreatedAndApplicantApplied': '工作创建的与申请人相比（天）',
  'label.hoursOfJobCreatedAndApplicantApplied': '工作创建的与申请人申请（小时）',
  'label.applicantHiredDateTime': '申请人雇用了DateTime',
  'label.daysOfApplicantAppliedAndApplicantHired': '申请人申请与雇用的申请人（天）',
  'label.hoursOfApplicantAppliedAndApplicantHired': '申请人申请与雇用的申请人（小时）',
  'label.jobApplicantStatus': '求职者身份',
  'label.slotApplicantStatus': '老虎机申请人状态',
  'label.slotStartDateTime': '插槽开始日期',
  'label.slotEndDateTime': '插槽结束日期',
  'label.applicantClockIn': '申请人时钟',
  'label.applicantClockOut': '申请人时钟',
  'label.applicantCancelledJob': '申请人取消了工作',
  'label.applicantCancellationFeedback': '申请人取消反馈',
  'label.ack1Done': 'ACK1完成了',
  'label.ack2Done': 'ACK2完成了',
  'label.jobCompleted': '工作完成',
  'label.hiringManagerID': '招聘经理ID',
  'label.hiringManagerFirstName': '招聘经理名字',
  'label.hiringManagerLastName': '招聘经理姓',
  'label.hiringMangerContactNumber': '招聘经理联系电话',
  'label.hiringManagerEmail': '招聘经理电子邮件',
  'label.hiringManagerRating': '招聘经理评级',
  'label.hiringManagerFeedback': '招聘经理反馈',
  'label.jobApplicantIsStatus': '求职者的身份',
  'label.slotApplicantIsStatus': '老虎机申请人的状态',
  'label.totalWorkingHours': '总工作时间',
  'label.jobId': '工作ID',
  'label.paymentId': 'Payment ID',
  'label.totalDays': '总天数',
  'label.bankTransfer': '银行转帐',
  'label.originalJobStart': '原始工作开始',
  'label.originalJobEnd': '原始工作端',
  'label.actualClockIn': '实际时钟in',
  'label.actualClockOut': '实际时钟',
  'label.dob': '多布',
  'label.jodCompletedJobs': 'JOD完成了工作',
  'label.view': '看法',
  'label.select': '选择',
  'label.locationManager': '位置经理',
  'label.earningPerHours': '每小时赚取',
  'label.workingDay': '选择所需的工作日',
  'label.dateAndTime': '日期和时间',
  'label.content': '内容',
  'label.value': '价值',
  'label.push': '推',
  'label.sms': '短信',
  'label.id': 'ID',
  'label.enabled': '已启用',
  'label.internalPermissions': '内部权限',
  'label.partnerPermissions': '合作伙伴许可',
  'label.permission': '允许',
  'label.roleName': '角色名称',
  'label.userName': '用户名',
  'label.role': '角色',
  'label.userDetails': '用户详细信息',
  'label.internalRoles': '内部角色',
  'label.partnerRoles': '合作伙伴角色',
  'label.changeWagePerHour': '每小时更改工资',
  'label.password': '密码',
  'label.oldPassword': '旧密码',
  'label.newPassword': '新密码',
  'label.confirmPassword': '确认密码',
  'label.credit': '信用',
  'label.jodCredits': 'JOD积分',
  'label.employeeName': '员工姓名',
  'label.consumedCreditsPreviousMonth': '消费信用<0 />（上个月）',
  'label.consumedCreditsCurrentMonth': '消费信用<0 />（当月）',
  'label.jodCreditAvailableWithHeadquarter': '总部可用的JOD信用',
  'label.test': '测试',
  'label.clockIn&ClockOut': '时钟进出',
  'label.minimumTotalJodCredit': '最低JOD信用额度',
  'label.maximumTotalJodCredit': '最高JOD信用',
  'label.jodCreditDeductionLevel': 'JOD信用减免级别',
  'label.mealBreakTime': '用餐时间',
  'label.availableCreditBeforeDeduction': '扣除前可用信用',
  'label.availableCreditAfterDeduction': '扣除后可用的信用',
  'label.assignBadge': '分配徽章',
  'label.badge': '徽章',
  'label.allCompanies': '所有公司',
  'label.futurePayment': '未来付款',
  'label.allFuturePayments': '所有未来的付款',
  'label.paymentDetails': '付款详情',
  'label.paymentSummary': '付款摘要',
  'label.unprocessedCredits': '未经处理的学分',
  'label.processedCredits': '处理的积分',
  'label.recentAddLocations': '最近添加了位置',
  'label.locationStatus.': '位置的状态',
  'label.noHistory': '没有历史',
  'label.noExperience': '没有经验',
  'label.candidateName': '候选人名字',
  'label.applyDate': '申请日期',
  'label.jobStartAndJobEnd': '工作开始 - 工作结束',
  'label.paymentProcessed': '付款处理',
  'label.notProcessed': '未处理',
  'label.clickCancelSorting': '点击取消排序',
  'label.clickToSortAsc': '点击排序升',
  'label.clickToSortDesc': '点击降序排序',
  'label.unknown': '未知',
  'label.receivedCredits': '收到的学分',
  'label.feedbackFromApplicant': '申请人的反馈',
  'label.managerCancellationReason': '经理取消职位的原因',
  'label.rejectApplicantReason': '经理拒绝申请人的原因',
  'label.applicantCancellationReason': '申请人取消的原因',
  'label.applicantCancellationDatetime': '申请人取消日期时间',
  'label.rejectByManagerDatetime': '经理拒绝日期时间',
  'label.managerCancellationDatetime': '经理职位取消日期时间',
  'label.applicantCancellationDocument': '申请人取消的文件',
  'label.feedbackFromManager': '经理的反馈',
  'label.ratingFromApplicant': '申请人的评分',
  'label.ratingFromManager': '经理的评分',
  'label.applicantInformation': '申请人信息',
  'label.aptusTermsOfUseAndConditionsForCustomers': 'APTUS 客户使用条款和条件',
  'label.mon': '周一',
  'label.tue': '周二',
  'label.wed': '周三',
  'label.thur': '周四',
  'label.fri': '星期五',
  'label.sat': '周六',
  'label.sun': '星期日',
  'label.all': '全部',
  'label.asc': '上升',
  'label.desc': '下降',
  'label.nric': 'NRIC/FIN',
  'label.lessThan1Years': '不到1年',
  'label.between1To2Years': '1 至 2 年之间',
  'label.moreThan2Years': '2年以上',
  'label.hour': '小时',
  'label.hours': '小时',
  'label.minute': '分钟',
  'label.minutes': '分钟',
  'label.checkboxSendMailToApplicant': '发送电子邮件给申请人',
  'label.certificates': '证书',
  'label.noCertificates': '没有证书',
  'label.age': '年龄',
  'label.locationAverageRating': '位置的平均评分',
  'label.transactionDate': '交易日期',
  'label.debit': '借记',
  'label.receiveSMSNotifications': '接收短信通知',
  'label.receiveEmailNotifications': '接收电子邮件通知',
  'label.receiveWhatsappNotifications': 'Receive Whatsapp Notifications',
  'label.hqViewOnlyStatusLabel': '只读',
  'label.unsuspended': '未暂停',
  'label.suspended': '暂停',

  'label.change': 'Change',
  'label.paymentApprovalDetails': 'Payment Approval Details',
  'label.pendingPaymentApprovalDetails': 'Pending Payment Approval Details',
  'label.attachment': 'Attachment',
  'label.updatedDate': 'Updated Date',
  'label.update_reason': '状态变更原因',
  'label.cancelled': '取消',
  'label.certificateStatus': `证书状态`,
  'label.disableCertificates': '禁用证书',
  'label.enableCertificates': '启用证书',
  'label.certifications': '认证',
  'label.requiredCertifications': '所需认证',
  'label.noRequiredCertificates': '无需认证',
  'label.color': '颜色',
  'label.appBanner': '应用横幅',
  'label.inAppUpdate': '应用内更新',
  'label.bannerImage': '横幅图片',
  'label.title': '标题',
  'label.subTitle': '副标题',
  'label.clickAction': '单击操作',
  'label.version': '版本',
  'label.forceUpgrade': '强制升级',
  'label.recommendUpgrade': '推荐升级',
  'label.latestVersion': '最新版本',

  'placeholder.email': '电子邮件',
  'placeholder.password': '密码',
  'placeholder.uploadServiceContract': '上传服务合同 *',
  'placeholder.uploadedServiceContract': '上传服务合同',
  'placeholder.uploadReasonAttachment': '上传支持文件',
  'placeholder.uploadBrandBanner': '上传品牌横幅（可选）',
  'placeholder.uploadedBanner': '品牌横幅上传',
  'placeholder.minExpectedCredit': '最低预期信用额',
  'placeholder.enterKeyword': '输入您的关键字',
  'placeholder.enterJobTitle': '输入您的职位',
  'placeholder.credit': '0.00',
  'placeholder.upload': '上传',
  'placeholder.uploaded': '上传',
  'placeholder.uploadLogo': '上传徽标',
  'placeholder.uploadBanner': '上传横幅',
  'placeholder.uploadProfilePicture': '上传个人资料图片',
  'placeholder.firstName': '请输入名字',
  'placeholder.lastName': '请输入LATS名称',
  'placeholder.jobTitle': '请输入职位',
  'placeholder.contactNumber': '请输入联系电话',
  'placeholder.workEmail': '请输入工作电子邮件',
  'placeholder.creditAmount': '信用额',
  'placeholder.comment': '评论',
  'placeholder.locationName': '请输入位置的名称',
  'placeholder.locationAddress': '请输入位置的地址',
  'placeholder.areaManager': '选择区域经理',
  'placeholder.managerType': '请选择经理类型',
  'placeholder.location': '请选择一个位置',
  'placeholder.locations': '请选择位置',
  'placeholder.applicantFirstName': '申请人的名字',
  'placeholder.applicantLastName': '申请人的姓氏',
  'placeholder.applicantDisplayName': '申请人的显示名称',
  'placeholder.applicantDateOfBirth': '申请人的出生日期',
  'placeholder.applicantNRIC/FIN': '申请人的NRIC/FIN',
  'placeholder.applicantContactNumber': '申请人的联系电话',
  'placeholder.applicantEmail': '申请人的电子邮件',
  'placeholder.applIcantSelectSchool': '选择申请人的学校',
  'placeholder.applicantReferralCode': '申请人的推荐代码',
  'placeholder.applicantSelectHQCompany': '选择申请人的总部公司',
  'placeholder.enterRating': '输入评分',
  'placeholder.selectStatus': '选择状态',
  'placeholder.selectDate': '选择日期',
  'placeholder.selectLocations': '选择位置',
  'placeholder.selectMonth': '选择一个月',
  'placeholder.selectCompany': '选择公司',
  'placeholder.status': '选择状态',
  'placeholder.startDate': '开始日期',
  'placeholder.endDate': '结束日期',
  'placeholder.enterTemplateName': '请输入工作模板',
  'placeholder.jobType': '请选择一个工作类型',
  'placeholder.noDateSelected': '未选择日期',
  'placeholder.pleaseSelectDate': '请选择一个日期。',
  'placeholder.jobDescription': '请输入职位描述',
  'placeholder.specialInstructions': '请输入特殊说明',
  'placeholder.hourlyRate': '请输入小时费率',
  'placeholder.pleaseEnterBankHolderName': '请输入银行持有人名称',
  'placeholder.pleaseEnterBankAccountNumber': '请输入银行帐号',
  'placeholder.selectLocation': '选择一个位置',
  'placeholder.feedback': '请输入评论',
  'placeholder.noAppliedApplicant': '没有申请人',
  'placeholder.selectTemplateName': '请选择一个模板',
  'placeholder.selectLocationManager': '请选择一个经理',
  'placeholder.pleaseSelectLanguage': '请选择语言。',
  'placeholder.currentWagePerHour': '每小时工资',
  'placeholder.newWagePerHour': '每小时新工资',
  'placeholder.allLocations': '所有位置',
  'placeholder.allJobs': '所有工作',
  'placeholder.allJobStatus': '所有JobStatus',
  'placeholder.pleaseSelectAnOption': '请选择一个选项',
  'placeholder.pleaseSelectAColumn': '请选择一列',
  'placeholder.pleaseEnterAssigningAmount': '请输入分配金额',
  'placeholder.update_reason': '请输入状态更改的原因',
  'placeholder.fileSelected': '文件已选定！',
  'placeholder.bannerImage': '横幅图片',
  'placeholder.title': '标题',
  'placeholder.subTitle': '副标题',
  'placeholder.description': '说明',
  'placeholder.clickAction': '单击操作',
  'placeholder.version': '版本',

  'title.dashboard': '仪表板',
  'title.editProfile': '编辑个人资料',
  'title.companies': '公司',
  'title.newCompany': '新公司',
  'title.editCompany': '编辑公司',
  'title.listOfCompanies': '公司清单',
  'title.listOfJobTypes': '工作类型清单',
  'title.jobTypes': '工作类型',
  'title.jobType': '工作类型',
  'title.newJobType': '新的工作类型',
  'title.editJobType': '编辑作业类型',
  'title.hqUsers': '总部用户',
  'title.listOfHQUsers': '总部用户列表',
  'title.newHQUsers': '总部经理注册',
  'title.editHQUsers': '编辑HQ Manager',
  'title.applicants': '申请人',
  'title.listOfApplicants': '申请人名单',
  'title.newApplicant': '新申请人',
  'title.editApplicant': '编辑申请人',
  'title.educationalInstitutes': '教育机构',
  'title.educationalInstitutesHasTrans': '教育<0 />机构',
  'title.listOfEducationInstitutes': '教育机构清单',
  'title.newEducationalInstitute': '新教育学院',
  'title.editEducationalInstitute': '编辑教育学院',
  'title.locations': '位置',
  'title.listOfLocations': '位置列表',
  'title.locationFeedback': '位置反馈',
  'title.billingRecords': '计费记录',
  'title.jodCredits': 'JOD积分',
  'title.assignCredit': '分配信用',
  'title.creditHistory': '信用记录',
  'title.debitCreditHistory': '借记和贷记历史',
  'title.payments': '付款',
  'title.jobSummary': '工作总结',
  'title.jobPayments': '工作付款',
  'title.paymentAdjustments': 'Job Payment Adjustments',
  'title.futurePayments': '未来付款',
  'title.report': '报告',
  'title.usersAndPermissions': '用户和权限',
  'title.users': '用户',
  'title.superHqUsers': '超级总部用户',
  'title.listOfInternalUsers': '内部用户列表',
  'title.listOfSuperHqUsers': '超级总部用户列表',
  'title.newInternalUser': '新的内部用户',
  'title.newSuperHqUser': '新的超级总部用户',
  'title.editInternalUser': '编辑内部用户',
  'title.editSuperHqUser': '编辑超级总部用户',
  'title.roles': '角色',
  'title.listOfRoles': '角色列表',
  'title.newRole': '新角色',
  'title.newInternalRole': '新的内部角色',
  'title.editRole': '编辑角色',
  'title.permissions': '权限',
  'title.configuration': '配置',
  'title.notifications': '通知',
  'title.notificationConfigurations': '通知配置',
  'title.other': '其他',
  'title.otherConfigurations': '其他配置',
  'title.badgeConfigurations': '徽章配置',
  'title.jodBadges': 'JOD徽章',
  'title.jodBadgesConfiguration': 'JOD徽章配置',
  'title.locationList': '位置列表',
  'title.addNewLocation': '添加新位置',
  'title.managers': '经理',
  'title.managerList': '管理器列表',
  'title.addNewManager': '添加新经理',
  'title.locationProfile': '位置配置文件',
  'title.jobTemplates': '工作模板',
  'title.jobTemplateList': '作业模板列表',
  'title.addNewJobTemplate': '添加新的作业模板',
  'title.jobPosting': '招聘启事',
  'title.allJobPosting': '所有职位发布',
  'title.createNewJob': '创建新工作',
  'title.manageJobs': '管理工作',
  'title.updateJobs': '更新作业',
  'title.cancelJobs': '取消作业',
  'title.jobOverview': '作业概述',
  'title.hiringOverview': '雇用概述',
  'title.creditOverview': '信用概述',
  'title.ratingOverview': '等级概述',
  'title.addNewAreaManager': '添加新的区域经理',
  'title.newLocation': '新位置',
  'title.editLocation': '编辑位置',
  'title.assignJodCredit': '分配JOD信用',
  'title.listOfManagers': '经理列表',
  'title.managerRegistration': '经理注册',
  'title.editLocationManager': '编辑位置管理器',
  'title.editAreaManager': '编辑区域经理',
  'title.editManager': '编辑管理器',
  'title.addJobTemplate': '添加作业模板',
  'title.editJobTemplate': '编辑作业模板',
  'title.jobHistory': '工作历史',
  'title.candidateProfile': '候选人资料',
  'title.listOfAllCandidates': '所有候选人名单',
  'title.candidateOfJob': '候选人',
  'title.candidatesOfNamedJob': '{{job}}作业的候选人',
  'title.candidatesOfJob': '求职者',
  'title.qrCode': '二维码',
  'title.jobInformation': '工作信息',
  'title.jobNote': '工作说明',
  'title.workerInformation': '工人信息',
  'title.jobEdit': '编辑工作',
  'title.copyJob': '复制工作',
  'title.howToUse': '如何使用',
  'title.howToPostJobs': '如何发布工作',
  'title.howToMakeSelections': '如何进行选择',
  'title.howToClockInAndOut': '如何进出',
  'title.currentAvailableCredit': '当前可用的信用',
  'title.redirecting': '重定向',
  'title.loadPage': '加载页面...',
  'title.unassignedCredit': '未分配的学分',
  'title.hqLevel': '总部级别',
  'title.uploadCertification': '上传认证',
  'title.hiringManager': '招聘经理',
  'title.appConfig': '应用程序配置',
  'title.superHqSelectCompany': '选择公司（总部）',
  'title.404PageNotFound': '404 Page Not Found',
  'title.paymentApproval': 'Payment Approval',
  'title.allJobs': '所有工作',
  'title.rolesAndPermissions': '角色和权限',
  'title.payslipRequest': '工资单请求',

  'title.current_status': '当前状态',
  'title.prev_status': '先前状态',
  'title.updated_by_id': '更新者（ID）',
  'title.updated_by': '更新者',
  'title.updated_at': '更新日期',
  'title.update_reason': '状态变更原因',
  'title.job_data_numbers': '工作数据编号',
  'title.job_selection': '工作选择',
  'title.certificates': '工作数据号',
  'title.listOfCertificates': '证书列表',
  'title.newCertificate': '新证书',
  'title.editCertificate': '编辑证书',

  'message.changePassword': '更改密码',
  'message.confirmPasswordMustMatchPassword': '密码必须匹配',
  'message.totalNumberOf': '总数',
  'message.numberOf': '数量',
  'message.actionFailed': '动作失败了。请再试一次！',
  'message.somethingWentWrong': '出问题了。请再试一次！',
  'message.somethingWentWrong1': '出了些问题！',
  'message.emailOrPasswordIncorrect': '电子邮件或密码不正确',
  'message.enableCompany': '您选择启用这家公司。',
  'message.disableCompany': '您选择禁用这家公司。',
  'message.disableAccount': '您选择禁用此帐户。',
  'message.enableAccount': '您选择启用此帐户。',
  'message.validateMinLessThanMaxCredits': '最低可用信用必须小于最大可用信用。',
  'message.validateMaxGreaterThanMinCredits': '最大可用信用必须大于最低可用信用。',
  'message.validateOnlyIntegerNumber': '仅允许数字为整数。',
  'message.pleaseEnterContactNumber': '请输入联系电话。',
  'message.contactNumberMustBeNumber': '联系电话必须是号码',
  'message.contactNumberMustBe8Digits': '联系电话必须是8位。',
  'message.contactNumberMustBe9Digits': '联系电话必须是9位。',
  'message.contactNumberMustBe10Digits': '联系电话必须是10位。',
  'message.contactNumber9Or10': '联系电话必须是 9 或 10 位数字。',
  'message.minLessThanMaxConsumeCredit': '最低消费信用必须小于最大消费信用。',
  'message.maxGreaterThanMaxConsumeCredit': '最大消费的信用必须大于最低消费信用。',
  'message.validateNotBeGreaterThan255Characters': '标题不得大于255个字符。',
  'message.validateTypeFileCompanyAvatar': '公司Avatar文件名必须是类型的文件：{{files}}。',
  'message.validateTypeFileCompanyLogo': '公司徽标文件名必须是类型的文件：{{files}}。',
  'message.validateTypeFileJobTypeLogo': '作业类型徽标文件名必须是类型的文件：{{files}}。',
  'message.validateTypeFileContractService': '合同服务文件名必须是类型的文件：{{files}}。',
  'message.pleaseUploadServiceContract': '请上传服务合同。',
  'message.validateTypeFileCompanyBanner': '公司横幅文件名必须是类型的文件：{{files}}。',
  'message.registrationCompleted': '您的注册已完成。',
  'message.changeCompleted': '您的更改已完成。',
  'message.wantToSubmit': '你想提交吗？',
  'message.wantToSave': '你要保存吗？',
  'message.wantToContinue': '你想继续吗？',
  'message.pleaseSelectCompany': '请选择一家公司',
  'message.selectCompany': '选择一家公司',
  'message.pleaseEnterCompanyName': '请输入公司的名字',
  'message.pleaseEnterCompanyAddress': '请输入公司的地址',
  'message.pleaseEnterBusinessCompany': '请输入公司的业务号码',
  'message.pleaseEnterMinJODCreditLimit': '请输入有效的最小JOD信用额度',
  'message.enableJobType': '您选择启用这种工作类型。',
  'message.disableJobType': '您选择禁用这种工作类型。',
  'message.pleaseEnterJobTitle': '请输入职位',
  'message.pleaseEnterJobDescription': '请输入主要职位描述',
  'message.pleaseEnterComment': '请输入评论',
  'message.commentLengthCannotExceed255Characters': '評論長度不能超過255個字符',
  'message.minAvailablePositionsLargerThanOrEqualTo0': '最低可用位置必须大于或等于0。',
  'message.maxAvailablePositionsLargerThanOrEqualTo0': '最大可用位置必须大于或等于0。',
  'message.minAvailableCompaniesLargerThanOrEqualTo0': '最低可用公司必须大于或等于0。',
  'message.maxAvailableCompaniesLargerThanOrEqualTo0': '最大可用公司必须大于或等于0。',
  'message.minLessThanMaxAvailablePositions': '最低可用位置必须小于最大可用位置。',
  'message.maxGreaterThanMinAvailablePositions': '最大可用位置必须大于最低可用位置。',
  'message.minLessThanMaxAvailableCompanies': '最低可用的公司必须小于最大可用公司。',
  'message.maxGreaterThanMinAvailableCompanies': '最大可用的公司必须大于最低可用的公司。',
  'message.pleaseEnterFirstName': '请输入名字',
  'message.pleaseEnterLastName': '请输入姓氏',
  'message.pleaseEnterWorkEmail': '请输入工作电子邮件',
  'message.workEmailMustBeValidEmailAddress': '工作电子邮件必须是一个有效的电子邮件地址。',
  'message.firstNameHasMaximum100Characters': '名字最多有100个字符。',
  'message.firstNameHasMinimum1Character': '名字至少有 1 个字符。',
  'message.firstNameAlphabetsOnlyNoNumericNoSpecialCharacters':
    "名字必须仅是字母，没有数字，没有特殊字符（除了 - /'@）。",
  'message.lastNameHasMaximum100Characters': '姓氏最多有100个字符。',
  'message.lastNameHasMinimum1Character': '姓氏至少有1个字符。',
  'message.lastNameAlphabetsOnlyNoNumericNoSpecialCharacters':
    "姓氏必须仅是字母，没有数字，没有特殊字符（除了 - /'@）。",
  'message.enableLocation': '您选择启用此位置。',
  'message.disableLocation': '您选择禁用此位置。',
  'message.noData': '没有可用数据',
  'message.locationNotFound': '找不到位置',
  'message.onlyAllowTwoDecimalPlace': '仅允许最多2个小数位的小数。',
  'message.commentRequired': '请输入评论。',
  'message.creditAmountRequired': '请输入有效的信用额。',
  'message.locationLogoMustBe': '位置徽标文件名必须是类型的文件：{{files}}。',
  'message.locationNameRequired': '请输入位置的名称。',
  'message.locationAddressRequired': '请输入位置的地址。',
  'message.minJODCreditRequired': '请输入有效的最低JOD信用额度。',
  'message.firstNameRequired': '请输入名字。',
  'message.lastNameRequired': '请输入姓氏。',
  'message.jobTitleRequired': '请输入职位。',
  'message.workEmailRequired': '请输入工作电子邮件。',
  'message.enableManager': '您选择启用此经理。',
  'message.disableManager': '您选择禁用此经理。',
  'message.managerTypeRequired': '请选择管理器类型。',
  'message.pleaseEnterApplicantFirstName': '请输入申请人的名字',
  'message.pleaseEnterApplicantLastName': '请输入申请人的姓氏',
  'message.pleaseEnterReasonHere': '请在这里输入您的理由',
  'message.enableAccountBelowPreviousReasonOfDisabling':
    '您选择启用此帐户。 <0 />以下是您以前禁用此帐户的原因。',
  'message.disableAccountPleaseClarifyYourReason': '您选择禁用此帐户。 <0 />请澄清您的原因',
  'message.choseMarkApplicantIdentityAsValid': '您选择将申请人的身份标记为有效。',
  'message.choseMarkApplicantIdentityAsInvalid': '您选择将申请人的身份标记为无效。',
  'message.displayNameRequired': '请输入显示名称。',
  'message.displayNameMax100Char': '显示名称最多有100个字符。',
  'message.displayNameMin2Char': '显示名称至少有2个字符。',
  'message.displayNameMustBeUserName':
    "显示名称必须仅是字母，没有数字，没有特殊字符（除了 - /'@）。",
  'message.pleaseEnterApplicantDateOfBirth': '请输入申请人的出生日期',
  'message.ageBetween': '用户年龄必须在 {{from}} 至 {{to}} 岁之间。',
  'message.pleaseEnterGenderInOptionsAbove': '请在上面的选项中输入性别。',
  'message.formatInvalidInNRIC/FIN': 'NRIC/FIN格式无效。',
  'message.NRIC/FINrequired': '请输入NRIC/FIN。',
  'message.finDocumentRequired': '请上传鳍文件。',
  'message.pleaseEnterApplicantContactNumber': '请输入申请人的联系电话。',
  'message.emailMustBeValidEmailAddress': '该电子邮件必须是一个有效的电子邮件地址。',
  'message.pleaseEnterApplicantEmail': '请输入申请人的电子邮件。',
  'message.validateTypeFileApplicantFoodHygieneCert': '申请人食品卫生证书文件名必须是类型文件',
  'message.pleaseUploadFoodHygieneCert': '请上传食品卫生证书。',
  'message.minimumRatingLargerThanOrEqualTo0': '最小额定值必须大于或等于0。',
  'message.maximumRatingLargerThanOrEqualTo0': '最大额定值必须大于或等于0。',
  'message.amountJobGreaterThanOrEqualTo0': '工作的数量必须大于或等于0。',
  'message.minimumRatingLessThan5': '最低评级必须小于5。',
  'message.maximumRatingLessThan5': '最大评分必须小于或等于5。',
  'message.minimumRatingLessThanMaximumRating': '最低额定值必须小于最大评分。',
  'message.maximumRatingGreaterThanMinimumRating': '最大评分必须大于最低额定值。',
  'message.choseDisableInstitute': '您选择禁用该研究所。',
  'message.choseEnableInstitute': '您选择启用该研究所。',
  'message.pleaseEnterInstituteName': '请输入研究所的名字',
  'message.pleaseEnterInstituteDescription': '请输入主要研究所描述',
  'message.pleaseEnterInstituteAddress': '请输入研究所的地址',
  'message.enableJobTemplate': '您选择启用此作业模板。',
  'message.disableJobTemplate': '您选择禁用此作业模板。',
  'message.templateNameRequired': '请输入工作模板',
  'message.templateNameMax100Char': '模板名称最多有100个字符。',
  'message.templateNameMin2Char': '模板名称至少有2个字符。',
  'message.jobTitleMax100Char': '职位最多有100个字符。',
  'message.jobTitleMin2Char': '职位至少有2个字符。',
  'message.jobTypeRequired': '请选择一个工作类型',
  'message.jobDescriptionRequired': '请输入职位描述',
  'message.jobStartDateRequired': '请选择工作开始日期。',
  'message.jobStartDateBeforeJobEndDate': '工作开始日期必须在工作结束日期之前',
  'message.jobEndDateRequired': '请选择工作结束日期。',
  'message.jobEndDateAfterJobStartDate': '工作结束日期必须在工作开始日期之后',
  'message.hourlyRateGreaterThanZero': '小时费率必须大于0。',
  'message.dateMustBeBeforeOrEqualCurrentDateTime': '该日期必须在当前的日期之前或等于当前',
  'message.pleaseEnterAssigningAmount': '请输入有效分配金额',
  'message.confirmAssignCredits': '您想选择选择吗？',
  'message.minimumAssignAmountLessThanMaximumAssignAmount': '最小分配金额必须小于最大分配金额。',
  'message.maximumAssignAmountGreaterThanMinimumAssignAmount': '最大分配金额必须大于最低分配金额。',
  'message.selectedPayment': '您已经选择<0> <1> {{countselected}}} </1> </0>付款',
  'message.selectedMultiplePayment': '您已经选择<0> <1> {{countselected}}} </1> </0>付款',
  'message.wantToProcessThesePayments': '您想处理这些付款吗？',
  'message.applicantMustCompleteFeedbackBeforePaymentIsProcessed':
    '申请人必须在处理付款之前完成反馈',
  'message.applicantJobDetailsOnlyShownWhenClockOutDone': '申请人的工作细节仅在完成时钟后才显示。',
  'message.jobNotFound': '找不到工作',
  'message.pleaseSelectClockInTime': '请及时选择时钟。',
  'message.pleaseSelectClockOutTime': '请选择时钟时间。',
  'message.pleaseSelectBreakTime': '请选择休息时间。',
  'message.pleaseSelectJodBadge': '请分配用户徽章。',
  'message.pleaseEnterWagePerHour': '请每小时输入有效的工资。',
  'message.pleaseSelectBank': '请选择一家银行',
  'message.wagePerHourMustBeLargerThan0': '每小时的工资必须大于0。',
  'message.clockInTimeMustBeBeforeClockOutTime': '时钟必须在时钟之前',
  'message.clockOutTimeMustBeAfterClockInTime': '时钟的时间必须在时钟之后',
  'message.breakTimeLessThanTotalWorkingHours': '休息时间必须小于总工作时间。',
  'message.pleaseEnterAllowance': '请输入有效的津贴。',
  'message.jodAllowanceLargerThan0': 'JOD津贴必须大于0。',
  'message.bankHolderNameLessThanOrEqualTo35Char': '银行持有人的名称必须小于或等于35个字符。',
  'message.bankAccountNumberMustBeBetween8to15Digits': '银行帐号必须在8到15位之间',
  'message.bankAccountNumberMustBeAtLeast': '银行帐号必须至少为 {{count}} 位数字',
  'message.bankAccountNumberMustBeBetween9to12Digits': '银行帐号必须在9到12位之间',
  'message.min12To16DigitsNoDashOtherSpecialChar': '最小12至16位数字，没有破折号和其他特殊字符。',
  'message.min9To12DigitsNoDashOtherSpecialChar': '最小9至12位数字，没有破折号和其他特殊字符。',
  'message.minCountDigitsNoDashOtherSpecialChar': '最少 {{count}} 位数字，无破折号和其他特殊字符。',
  'message.min8To15DigitsNoDashOtherSpecialChar': '最小8至15位数字，没有破折号和其他特殊字符。',
  'message.bankHolderNameFormatIsInvalid': '银行持有人名称格式无效。',
  'message.bankAccountNumberFormatIsInvalid': '银行帐号格式无效。',
  'message.limitTo35CharAlphaOnlyNoDashOtherSpecialChar':
    '限制为35个字符，仅alpha，没有破折号和其他特殊字符',
  'message.wantToProcessThisPayment': '您想处理这笔付款吗？',
  'message.wantToDeleteJob': '您要删除<0/>这项工作吗？',
  'message.wantToRejectJob': '您要拒绝<0/>这项工作吗？',
  'message.wantToApproveJob': '您要批准<0/>这项工作吗？',
  'message.pleaseScanQrCode': '请扫描QR码',
  'message.cancelApplicant': '请确认是否要取消此申请人？',
  'message.cancelApplicantNote': '这份工作不会自动重新发布。',
  'message.clockInBeforeClockOut': '时钟必须在时钟之前。',
  'message.clockOutAfterClockIn': '时钟的时间必须在时钟之后。',
  'message.ratingRequired': '请选择一个评分。',
  'message.updateStatusReason': '请输入更新原因。',
  'message.workingHoursMoreThan18Hours': '时钟进出时间超过18小时。',
  'message.deletedAccount': '删除帐户',
  'message.haveSelected': '您已选择',
  'message.wantToProcess': '你想处理吗',
  'message.templateNameOptionRequired': '请选择一个模板。',
  'message.locationRequired': '请选择一个位置。',
  'message.dateRangeRequired': '请选择一个日期范围。',
  'message.jobStartDateAfterCurrent': '作业的开始日期必须在当前日期之后',
  'message.jobEndDateAfterCurrent': '作业的结束日期必须是在当前日期之后',
  'message.jobStartBeforeJobEnd': '启动日期必须在末日结束之前',
  'message.earningPerHourRequired': '请输入每小时有效的收入。',
  'message.earningPerHourLargerThan0': '每小时的收入必须大于0。',
  'message.atLeastOneWorkingDay': '请选择至少一个工作日。',
  'message.postingNewJob': '您正在发布新工作。',
  'message.wantToChangeValueOfSelectedCountOtherConfiguration':
    '您要更改{{selectedCount}}配置的<0 />值吗？',
  'message.wantToChangeValueOfSelectedCountMultipleOtherConfiguration':
    '您要更改{{selectedCount}}配置的<0 />值吗？',
  'message.wantToChangeValueOfBadgeConfiguration': '您要更改{{label}}配置的<0 />值吗？',
  'message.wantToChangeStatusOfEmailNotification':
    '您要更改{{selectedCount}}电子邮件通知的<0 />状态吗？',
  'message.wantToChangeStatusPushNotification': '您要更改{{selectedCount}}推送通知的<0 />状态吗？',
  'message.wantToChangeStatusSMSNotification': '您要更改{{selectedCount}} SMS通知的<0 />状态吗？',
  'message.validateTypeFileLocationLogo': '位置徽标文件名必须是类型文件',
  'message.pleaseEnterPassword': '请输入密码',
  'message.pleaseEnterNewPassword': '请输入新密码',
  'message.pleaseEnterConfirmPassword': '请输入确认密码',
  'message.newPasswordMinimum6Char': '新密码必须至少6个字符',
  'message.confirmPasswordMinimum6Char': '确认密码必须至少为6个字符',
  'message.passwordMinimum6Char': '密码必须至少6个字符。',
  'message.choseDisableRole': '您选择禁用此角色。',
  'message.choseEnableRole': '您选择启用这个角色。',
  'message.choseDisableUser': '您选择禁用此用户。',
  'message.choseEnableUser': '您选择启用此用户。',
  'message.pleaseSelectRole': '请选择角色',
  'message.pleaseEnterRoleName': '请输入角色名称',
  'message.pleaseEnterDescription': '请输入说明',
  'message.descriptionMustBeGreaterThan255Char': '描述不得大于255个字符。',
  'message.minimumWageLargerOrEqual0': '最低工资必须大于或等于0',
  'message.minimumWageLessThanMaximumWage': '最低工资必须小于最高工资',
  'message.maximumWageGreaterThanMinimumWage': '最高工资必须大于最低工资。',
  'message.maximumWageLargerOrEqual0': '最高工资必须大于或等于0',
  'message.wantToUpdateWagePerHourForAJob':
    '您要更新<0/>每小时的工资从{{currentWage}}到{{newWage}} {{jobcount}} job',
  'message.wantToUpdateWagePerHourForMultiJob':
    '您要更新<0/>每小时的工资从{{currentWage}}到{{newWage}} {{jobcount}} jobs',
  'message.currentWageRequired': '请每小时输入有效的当前工资。',
  'message.currentWageLargerThan0': '当前每小时的工资必须大于0。',
  'message.newWageRequired': '请每小时输入有效的新工资。',
  'message.newWageLargerThan0': '每小时的新工资必须大于0。',
  'message.differentValidValueForCurrentAndNewWage': '请输入每小时当前和新工资的不同有效值。',
  'message.selectedAJob': '您已经选择<0> <1> {{selectedCount}}} </1> </0> job',
  'message.selectedMultipleJob': '您已经选择<0> <1> {{selectedCount}}} </1> </0>作业',
  'message.wantToCancelAJob': '你想取消这份工作吗',
  'message.wantToCancelMultipleJob': '您想取消这些工作吗',
  'message.totalNumberOfAvailableCredits': '<0 />可用积分的总数',
  'message.consumedCreditCurrentMonth': '消费信用<0 />（当月）',
  'message.jodCreditsAvailableUnderAreaManager': '区域经理下可用的JOD积分<0 />',
  'message.jodCreditsAvailableUnderLocationManager': 'JOD积分可用<0 />位置经理',
  'message.minimumTotalJodCreditLessThanMaximumTotalJodCredit': '最低JOD信用必须小于最高JOD信用。',
  'message.maximumTotalJodCreditGreaterThanMinimumTotalJodCredit':
    '最高JOD信用必须大于最低JOD信用。',
  'message.wantToChangeTheLogo': '您想更改徽标吗？',
  'message.totalNumberOfPartnerCompanies': '<0 />合作公司的总数',
  'message.totalNumberOfHQManagers': '<0 />总经理的总数',
  'message.totalNumberOfFuturePayments': '<0 />未来付款的总数',
  'message.minimumLessThanMaximumAvailableConsumed': '最低可用的消耗必须小于最大可用消耗的消耗。',
  'message.maximumGreaterThanMinimumAvailableConsumed':
    '可用的最大消耗必须大于最低可用消耗的消耗。',
  'message.totalNumberOfJobs': '<0 />作业的总数',
  'message.totalNumberOfRejectCandidates': '<0 />拒绝候选人的总数',
  'message.createdApplicantUnsuccess': '创建申请人失败了',
  'message.updatedApplicantUnsuccess': '更新的申请人未能成功',
  'message.enableApplicantUnsuccess': '使申请人失败',
  'message.disableApplicantUnsuccess': '禁用申请人失败',
  'message.updateApplicantIdentityStatusUnsuccess': '更新申请人身份状态状态失败',
  'message.downloadSuccess': '成功下载',
  'message.createdAreaManagerUnsuccess': '创建的区域经理失败了',
  'message.updatedAreaManagerUnsuccess': '更新的区域经理失败',
  'message.disableAreaManager': '禁用经理失败',
  'message.enableAreaManager': '启用经理失败',
  'message.createdCompanyUnsuccess': '创建公司未能成功',
  'message.updatedCompanyUnsuccess': '更新的公司未能成功',
  'message.disableCompanyUnsuccess': '禁用公司失败',
  'message.enableCompanyUnsuccess': '使公司失败',
  'message.bannerCompanyHasNotBeenUploaded': '横幅公司尚未上传！',
  'message.uploadBannerCompanyUnsuccess': '上传横幅公司失败',
  'message.exportSuccess': '成功导出',
  'message.changeStatusNotificationUnsuccess': '更改状态通知失败',
  'message.changeValueOtherConfigurationUnsuccess': '更改其他配置未能成功',
  'message.postNewAppVersionUnsuccess': '发布新应用版本失败',
  'message.assignCreditUnsuccess': '分配信用失败',
  'message.haveNoPermissionAccessFeature': '您无权访问此功能',
  'message.userAdminLogoFileHasNotBeenUploadYet': '用户管理徽标文件尚未上传！',
  'message.updateFailed': '更新失败',
  'message.getAdminLogoFailed': '获取管理徽标失败',
  'message.disableEducationalInstitutesUnsuccess': '禁用教育学院失败',
  'message.enableEducationalInstitutesUnsuccess': '使教育学院失败',
  'message.createdHQManagerUnsuccess': '创建HQ Manager失败',
  'message.updatedHQManagerUnsuccess': '更新的HQ Manager失败',
  'message.disableHQUserUnsuccess': '禁用HQ用户失败',
  'message.enableHQUserUnsuccess': '禁用HQ用户失败',
  'message.createdUserUnsuccess': '创建的用户未能成功',
  'message.updatedUserUnsuccess': '更新的用户失败',
  'message.disableUserUnsuccess': '禁用用户失败',
  'message.enableUserUnsuccess': '使用户失败',
  'message.disableJobTemplateUnsuccess': '禁用工作模板失败',
  'message.enableJobTemplateUnsuccess': '启用工作模板失败',
  'message.createdJobTemplateUnsuccess': '创建的作业模板失败了',
  'message.updatedJobTemplateUnsuccess': '更新的作业模板失败',
  'message.createdJobTypeUnsuccess': '创建的作业类型失败',
  'message.updatedJobTypeUnsuccess': '更新的作业类型失败',
  'message.disableJobTypeUnsuccess': '禁用工作类型失败',
  'message.enableJobTypeUnsuccess': '启用工作类型失败',
  'message.createdCertificateUnsuccess': '创建证书失败',
  'message.updatedCertificateUnsuccess': '更新证书失败',
  'message.disableCertificateUnsuccess': '禁用证书失败',
  'message.enableCertificateUnsuccess': '启用证书失败',
  'message.createdNewJobUnsuccess': '创建新工作未能成功',
  'message.updatedNewJobUnsuccess': '不成功的新工作不成功',
  'message.deletedJobFailed': '删除作业失败',
  'message.approveJobFailed': '批准工作失败',
  'message.cancelApplicantFailed': '取消申请人失败',
  'message.cancelUnsuccess': '取消失败',
  'message.createdLocationManagerUnsuccess': '创建的位置管理器失败了',
  'message.updatedLocationManagerUnsuccess': '更新的位置管理器失败',
  'message.createdLocationUnsuccess': '创建的位置失败了',
  'message.updatedLocationUnsuccess': '更新的位置未能成功',
  'message.disableLocationUnsuccess': '禁用位置失败',
  'message.enableLocationUnsuccess': '启用位置失败',
  'message.confirmUnsuccess': '确认失败',
  'message.downloadApplicantJobSummaryUnsuccess': '下载申请人的工作摘要失败',
  'message.createdRoleUnsuccess': '创建角色失败',
  'message.updatedRoleUnsuccess': '更新的角色失败',
  'message.disableRoleUnsuccess': '禁用角色失败',
  'message.enableRoleUnsuccess': '使角色失败',
  'message.passwordChangeFailed': '密码更改失败',
  'message.youNoPermissionAccessPage': '您无权访问此页面',
  'message.hourlyRate': '请输入有效的小时费率',
  'message.tableSummary': '顯示 {{from}} 到 {{to}} 的 {{total}} 條記錄',
  'message.invalidPasswordResetLink': '看起来您单击无效的密码重置链接。请再试一次。',
  'message.duplicatedEmailError': '该电子邮件已被收集。',
  'message.valueMustBeAlphabeticOnly': '值只能是字母。',
  'message.candidate': '候选人',
  'message.candidates': '考生',
  'message.valueMustBeNumericOnly': '值只能是数字。',
  'message.valueMustBeAlphaNumeric': '该值必须是字母数字。',
  'message.pleaseSelectAverageRating': '请选择平均评分',
  'message.commonRequired': '必填字段',
  'message.pleaseUploadAttachment': 'Please upload attachment.',
  'message.locationManagerIsNull':
    '您在发布新职位时未选择地点经理。地点经理将无法看到此职位发布。请选定一位地点经理以避免这种情况。',
  'message.pleaseSelectReasonCancellation': '请选择取消此职位的原因。',
  'message.pleaseSelectApplicantReasonCancellation': '请选择申请人取消的原因。',
  'message.disableCertificate': '您选择禁用此证书。',
  'message.enableCertificate': '您选择启用此证书。',
  'message.pleaseEnterCertificateName': '请输入证书名称',
  'message.pleaseEnterCertificateDescription': '请输入证书描述',
  'message.updateApplicantSuspendStatusUnsuccess': '更新申请人暂停状态失败',
  'message.choseMarkApplicantAsSuspended': `您选择将申请人标记为暂停。`,
  'message.choseMarkApplicantAsUnsuspended': `您选择将申请人标记为未暂停。`,
  'message.pleaseChooseColor': '请选择颜色',

  'value.all': '全部',
  'value.enable': '使能够',
  'value.disable': '禁用',
  'value.include': '包括',
  'value.exclude': '排除',
  'value.male': '男性',
  'value.female': '女性',
  'value.other': '其他',
  'value.recentAddedLocations': '最近添加了位置',
  'value.enableLocations': '启用位置',
  'value.disableLocations': '禁用位置',
  'value.location': '地点',
  'value.area': '区域',
  'value.locationManager': '位置经理',
  'value.areaManager': '区域经理',
  'value.recentAddedManagers': '最近添加了经理',
  'value.disableManagers': '禁用经理',
  'value.enableManagers': '启用经理',
  'value.jobWithApplicants': '与申请人一起工作',
  'value.recentAddedJobTemplates': '最近添加了工作模板',
  'value.enableJobTemplates': '启用工作模板',
  'value.disableJobTemplates': '禁用工作模板',
  'value.yearOld': '{{age}}年龄',
  'value.inThisSlot': '仅在这个插槽中。',
  'value.inAllSlot': '在所有撤消插槽中。',
  'value.singleDayJobs': '单日工作',
  'value.multiDayJobs': '多日工作',
  'value.pending': '待办的',
  'value.open': '打开',
  'value.active': '积极的',
  'value.completed': '完全的',
  'value.cancelled': '取消',
  'value.expiredWithNoApplicants': '已过期 - 无申请人',
  'value.expiredNoSelection': '已过期 - 未进行选择',
  'value.activeButNotClockIn': '活跃（尚未计时）',
  'value.jan': '扬',
  'value.feb': '2月',
  'value.mar': '3月',
  'value.apr': '4月',
  'value.may': '可能',
  'value.jun': '六月',
  'value.jul': '七月',
  'value.aug': '八月',
  'value.sep': '九月',
  'value.oct': '十月',
  'value.nov': '十一月',
  'value.dec': '十二月',
  'value.unprocessed': '未加工',
  'value.processed': '处理',
  'value.inProgress': '进行中',
  'value.perPage': '/ 页',
  'value.hired': '雇用',
  'value.rejected': '被经理拒绝',
  'value.rejectedPartially': '部分被经理拒绝',
  'value.selfCancelled': '自行取消',
  'value.ack1': 'ACK1',
  'value.ack2': 'ACK2',
  'value.clockedIn': '签到',
  'value.clockedOut': '打卡下班',
  'value.adminDisable': '管理员禁用被拒绝',
  'value.adminCompanyDisable': '由超级管理员禁用（公司）',
  'value.adminOutletDisable': '由超级管理员禁用（网点位置）',
  'value.adminApplicantDisable': '由超级管理员禁用（申请者）',
  'value.noShow': '没有出现',
  'value.unknown': '未知',
  'value.noOptions': '没有选项',
  'value.applied': '应用',
  'value.selected': '已选',
  'value.unselected': '未选中',
  'value.preSelectSelfCancelled': '取消（选择之前）',
  'value.timeOverlapReject': '时间重叠被拒绝',
  'value.postSelectSelfCanceled': '取消（选择之后）',
  'value.su': '星期日',
  'value.mo': '周一',
  'value.tu': '周二',
  'value.we': '周三',
  'value.th': '周四',
  'value.fr': '星期五',
  'value.sa': '周六',
  'value.unverifiedIdentity': '未经验证的身份',
  'value.validIdentity': '有效身份',
  'value.invalidIdentity': '身份无效',
  'value.unavailableIdentity': '不可用身份',
  'value.completedWithoutAppliedApplicant': '在没有申请的申请人的情况下完成',
  'value.completedWithoutHiringManager': '未经经理聘用而完成',
  'value.noHiring': '已過期但未僱用',
  'value.noApplicants': '由於沒有申請人，所以沒有可顯示的內容',
  'value.approved': 'Approved',
  'value.disapproved': 'Disapproved',
  'value.allRejected': 'All Applicants Rejected by Manager',
  'value.activeWithRejected': 'Active with Rejected Slots',
  'value.allCancelled': 'All Applicants Self Cancelled',
  'value.activeWithCancelled': 'Active with Cancelled Slots',
  'value.closedRejected': 'Closed - Rejected',
  'value.closedCancelled': 'Closed - Cancelled',
  'value.partiallyCompleted': 'Partially Completed',
  'value.unsuspended': '未暂停',
  'value.suspended': '暂停',
  'value.incomplete': 'No Show',
  'value.closed': '已关闭的工作',
  'value.expired': '已过期的工作'
};
